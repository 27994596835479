import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from 'components/Layout/Layout';
import MetaBar from 'components/UI/MetaBar/MetaBar';
import Section from 'components/UI/Section/Section';
import Text from 'components/UI/Text/Text';
import SharedLink from 'components/UI/SharedLink/SharedLink';
import ContentfulRichText
  from 'components/UI/ContentfulRichText/ContentfulRichText';
import Button from 'components/UI/Button/Button';
import MEDIA from 'helpers/mediaTemplates';

const StyledTitle = styled(Text)`
  margin: 10px 0 20px;
  ${MEDIA.TABLET`
    margin: 10px 0 40px;
  `}
`;
const StyledImage = styled.div`
  width: 100%;
  height: 164px;
  max-height: 50vh;
  margin-top: -40px;
  margin-bottom: -40px;

  background: url(${({ url }) => url}) no-repeat center;
  background-size: cover;

  ${MEDIA.PHONE`
    height: 320px;
    margin-bottom: 24px;
  `}
  ${MEDIA.TABLET`
    height: 540px;
  `}
`;

const Post = ({ pageContext }) => {
  const {
    id,
    createdAt,
    typeOfService,
    image,
    title,
    shortDescription,
    content,
    slug
  } = pageContext;
  const shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=new.demtech.biz/news/${slug}&title=${title}&source=LinkedIn`;
  return (
    <Layout page="news">
      {image && <StyledImage url={image.file.url} alt={image.description} />}
      <Section>
        <MetaBar
          tag={typeOfService}
          date={createdAt}
          additionalContent={(
            <SharedLink
              href={shareUrl}
              social="linkedin"
            />
          )}
        />
        {title && <StyledTitle as="h3" mt={0}>{title}</StyledTitle>}
        <ContentfulRichText richTextAST={content.json} />

        <Text as="h4" line>Similar Articles</Text>
        ...
      </Section>
    </Layout>
  );
}

export default Post;

export const pageQuery = graphql`
  query($id: String!) {
    allContentfulArticle(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          slug
          typeOfService
          createdAt
          image {
            file { url }
            description
          }
          title
          shortDescription{shortDescription}
          content {
            json
          }
        }
      }
    }
  }
`;
