import React from 'react';
import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Text from 'components/UI/Text/Text';

import linkedinIcon from './icons/linkedin.svg';

const SOCIAL = {
  linkedin: {
    text: 'LinkedIn',
    icon: linkedinIcon,
  },
};

const StyledSocialText = styled(Text)`
  color: #007BB6;
  display: none;
  ${MEDIA.TABLET`display: inline-flex;`}
`;

const StyledLink = styled(Text)`
  display: flex;
  align-items: center;
  font-weight: bold;

  ${({ icon }) => icon && css`
    color: #007BB6;

    &::before {
      content: '';
      display: inline-flex;
      height: 24px;
      width: 24px;
      margin-right: 8px;
      background: url(${icon}) no-repeat center;
    }
  `}
`;

const SharedLink = ({ href, social }) => {
  const { icon, text } = Object(SOCIAL[social]);
  return (

    <StyledLink as={href ? 'a' : 'span'} href={href} icon={icon} target='_blank'>
      Share
      <StyledSocialText>&nbsp;on&nbsp;{text}</StyledSocialText>
    </StyledLink>
  );
}

export default SharedLink;
